import { useEffect, useMemo, useState } from 'react'

import { BargeListing } from '../../components/BargeListing/BargeListing'
import { LaneFilters } from '../../components/Header/LaneFilters/LaneFilters'
import {
  LastUpdateIndicator,
  PrimaryMenu,
  PrimaryMenuRight,
  ToggleFilterScreenButton,
} from '../../components/Header/PrimaryMenu/PrimaryMenu'
import { fromBargesQuery } from '../../Domain/Barge'
import { Filter, searchParamsToFilter } from '../../Domain/BargeFilters'
import { getExcludedBargeTypes } from '../../Domain/BargeType'
import { excludePlacedToLoadStatuses } from '../../Domain/Trip'
import { useLaneBargesQuery } from '../../generated/graphql'
import { Layout } from '../../ui/Layout/Layout'
import { LoadingSpinner } from '../../ui/Spinner/Spinner'
import { isoZonedDateTime } from '../../utils/date'

export function BargesPage() {
  const [filters, setFilters] = useState<Filter>(searchParamsToFilter(window.location.search))
  const [isScreenVisible, setIsScreenVisible] = useState(false)

  useEffect(() => {
    function locationChange() {
      setFilters(searchParamsToFilter(window.location.search))
    }

    window.addEventListener('pushState', locationChange)
    window.addEventListener('popstate', locationChange)

    return () => {
      window.removeEventListener('pushState', locationChange)
      window.removeEventListener('popstate', locationChange)
    }
  }, [])

  const [{ data, fetching }] = useLaneBargesQuery({
    variables: {
      laneId: filters.lane ?? null,
      originId: filters.origin ?? null,
      destinationId: filters.destination ?? null,
      excludeBargeTypes: getExcludedBargeTypes(filters.excludeTanks, filters.excludeHoppers),
      excludeTripStatuses: filters.excludePlacedToLoad ? excludePlacedToLoadStatuses : null,
      excludeTboInfoBarges: filters.excludeTboInfo,
      excludeShuttleMoves: filters.excludeShuttleMoves,
      maxDraft: filters.maxDraft ?? null,
      time: filters.time ? isoZonedDateTime(filters.time) : null,
      includeTBOs: filters.includeTBOs
    },
  })

  const barges = useMemo(() => (data === undefined ? undefined : fromBargesQuery(data.lanes[0].barges)), [data])

  return (
    <Layout
      primaryMenu={({ isDrawerOpen, setIsDrawerOpen }) => (
        <PrimaryMenu isDrawerOpen={isDrawerOpen} setDrawerOpen={setIsDrawerOpen}>
          <PrimaryMenuRight>
            <LastUpdateIndicator />
            <ToggleFilterScreenButton handleClick={() => setIsScreenVisible(true)} />
          </PrimaryMenuRight>
        </PrimaryMenu>
      )}>
      {isScreenVisible ? (
        <LaneFilters
          filters={filters}
          setFilters={setFilters}
          setIsVisible={setIsScreenVisible}
          readOnly={false}
          isEnabled
        />
      ) : null}
      {fetching ? (
        <LoadingSpinner isFullScreen />
      ) : (
        <BargeListing
          lane={filters.lane}
          barges={barges ?? []}
          isSelectable={() => false}
          pinnedBarges={[]}
          setPinnedBarges={() => {}}
          excludedBarges={[]}
          setExcludedBarges={() => {}}
        />
      )}
    </Layout>
  )
}
