import React, { FC } from 'react'

import FormOrSummary from '../FormOrSummary'
import { StageData, VesselSelectionSummaryValues } from '../types'

import VesselSelectionForm from './VesselSelectionForm'
import VesselSelectionSummary from './VesselSelectionSummary'


const VesselSelectionBlock: FC<StageData<any, VesselSelectionSummaryValues, any>> = ({
  isSelected,
  actions,
  form,
  summary,
  initParameters,
}) => {
  const { data, onChange } = form

  return (
    <FormOrSummary
      title="Vessels"
      isSelected={isSelected}
      actions={actions}
      form={<VesselSelectionForm data={data} onChange={onChange} initParameters={initParameters} />}
      summary={<VesselSelectionSummary description={summary.description} hasTurnboat={data.hasTurnboat} />}
    />
  )
}

export default VesselSelectionBlock
