import { useMemo, useState } from 'react'

import { OverviewBarge, distinctDestinations, getBargeNames } from '../../Domain/Barge'
import { LaneId, LoadStatus } from '../../generated/graphql'
import { colorByOptions, ColorByType, TableColumnConfig } from '../../ui/Table/TableColumnConfig'
import { handleCSVDownload } from '../../utils/downloadFile'
import {
  BargesTable,
  columnKeys,
  columns,
  DEFAULT_HIDDEN_COLUMNS,
  getDataByColumns,
  getGroupedDataByColumns,
} from '../Table/BargesTable'
import ColorBySelector from '../Table/ColorBySelector'
import { TableLegend } from '../Table/TableLegend'
import { ColumnConfig, useColumnConfig } from '../Table/useColumnConfig'

import styles from './BargeListing.module.scss'
import { LayoutSection, ExportCSV, LayoutSectionHeader, CopyNames, Wrapper } from './Layout'

const defaultColumns = columnKeys.map((id, index) => ({
  id,
  name: columns[id].label,
  defaultOrder: index,
}))

const sortColumns = (cs: ColumnConfig['columns']) => (a: string, b: string) => {
  // type system can't be sure of a result but the config is based on the columns
  const aPos = cs.find(({ id }) => id === a)!.defaultOrder
  const bPos = cs.find(({ id }) => id === b)!.defaultOrder

  if (aPos === bPos) return 0
  return aPos > bPos ? 1 : -1
}

type BargeListingProps = {
  barges: OverviewBarge[]
  lane?: LaneId
  isSelectable: () => boolean
  pinnedBarges: string[]
  setPinnedBarges: (bargeIds: string[]) => void 
  excludedBarges: string[]
  setExcludedBarges: (ids: string[]) => void
}


export function BargeListing({
  barges,
  lane,
  isSelectable,
  pinnedBarges,
  setPinnedBarges,
  excludedBarges,
  setExcludedBarges,
}: BargeListingProps) {
  const [isGrouped, setIsGrouped] = useState(true)

  const { config, setConfig } = useColumnConfig(defaultColumns, DEFAULT_HIDDEN_COLUMNS)

  const [colorBy, setColorBy] = useState<ColorByType>(ColorByType.None)

  const configuredColumns = useMemo(
    () => columnKeys.filter(key => !config.hidden.includes(key)).sort(sortColumns(config.columns)),
    [config]
  )

  const numberOfDestinations = useMemo(() => barges.reduce(distinctDestinations, []).length, [barges])
  const numberOfLoaded = useMemo(
    () => barges.filter(b => b.expectedTripLoadStatus === LoadStatus.Loaded).length,
    [barges]
  )

  const numberOfEmpties = useMemo(
    () => barges.filter(b => b.expectedTripLoadStatus === LoadStatus.Empty).length,
    [barges]
  )
  return (
    <Wrapper>
      <LayoutSection className={styles.listing}>
        <LayoutSectionHeader>
          <div className={styles.tableTotals}>
            <div className={styles.element}>Total barges: {barges.length}</div>
            <div className={styles.element}>Number LD: {numberOfLoaded}</div>
            <div className={styles.element}>Number MT: {numberOfEmpties}</div>
            <div className={styles.element}>Number of destinations: {numberOfDestinations}</div>
          </div>
          <LayoutSectionHeader className={styles.sectionHeaderRight}>
            <ColorBySelector
              colorBy={colorBy}
              colorByOptions={colorByOptions}
              handleColorByChange={setColorBy}
            />
            <TableColumnConfig
              config={config}
              defaultHidden={DEFAULT_HIDDEN_COLUMNS}
              defaultColumns={defaultColumns}
              handleApply={setConfig}
            />
            <CopyNames getNames={() => getBargeNames(barges).join(', ')} />
            <ExportCSV
              exportCSV={() =>
                handleCSVDownload(
                  isGrouped ? { data: getGroupedDataByColumns(barges, lane) } : { data: getDataByColumns(barges) }
                )
              }
            />
          </LayoutSectionHeader>
        </LayoutSectionHeader>
        <BargesTable
          isSelectable={isSelectable}
          lane={lane}
          columns={configuredColumns}
          barges={barges}
          setIsGrouped={setIsGrouped}
          setPinnedBarges={setPinnedBarges}
          pinnedBarges={pinnedBarges}
          setExcludedBarges={setExcludedBarges}
          excludedBarges={excludedBarges}
          colorBy={colorBy}
        />
        <TableLegend
          hasDerivedInfo={config.hidden.filter(v => ['dropOffLocation', 'pickupLocation'].includes(v)).length < 2}
        />
      </LayoutSection>
    </Wrapper>
  )
}
