import { format} from 'date-fns-tz'

import { Time } from '../../components/Header/TimeSelector/TimeInput'
import { showRiverLocation } from '../../Domain/River'

import type { NominatableBoat } from '../../Domain/Nomination'
import type { HubLike, HubLikeId } from '../../generated/graphql'


const ELLIPSIS = '...'
const TRUE_LABEL = 'True'
const FALSE_LABEL = 'False'

export const defaultTimezone = 'America/Chicago' 
export const inputTimezone = 'Europe/Berlin'
export const formatString = "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"

export function formatBoolean(b: boolean): string {
  return b ? TRUE_LABEL : FALSE_LABEL
}

export function formatOptional<T>(v: T | null | undefined, formatter: (v: T) => string): string {
  return v ? formatter(v) : ELLIPSIS
}

export function formatHubLike(id: HubLikeId, hubs: Record<HubLikeId, HubLike>): string {
  const hub = hubs[id]
  return `${showRiverLocation(hub.riverLocation)} (${hub.label})`
}

export function formatBoat(id: string, boats: NominatableBoat[]): string {
  const boat = boats.find(b => b.boatId === id)
  return boat ? `(${boat.abbreviation}) ${boat.name}` : `Unknown boat (${id})`
}

export function formatHours(hours: number) {
  return `${hours.toFixed(2)} hours`
}

export function formatPercents(fraction: number) {
  return `${(fraction * 100).toFixed(1)} %`
}

export const formatDate = (date: Date): string => {
  return format(date, 'dd/MM/yyyy')
}

export const formatTime = (time?: Time | null): string => {
  if (!time || time.hours === null || time.minutes === null) {
    return '' 
  }
  const hours = String(time.hours).padStart(2, '0')
  const minutes = String(time.minutes).padStart(2, '0')

  return `${hours}:${minutes}`
}
