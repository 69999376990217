import React from 'react'

import { CalendarMonth, Close } from '@mui/icons-material'
import classNames from 'classnames'
import ReactDatePicker from 'react-datepicker'

import styles from './styles.module.scss'

type DateInputProps = {
  selectedDate?: Date | null
  onChange: (date: Date | null) => void
  isDisabled: boolean
  minDate?: Date
  placeholderText?: string
  inputContainerClassName?: string
  iconClassName?: string
  inputClassName?: string
  isDisabledClassName?: string
  closeButtonClassName?: string
  closeIconClassName?: string
}

const DateInput: React.FC<DateInputProps> = ({
  selectedDate,
  onChange,
  isDisabled,
  minDate = new Date(),
  placeholderText = 'Select a date',
  inputContainerClassName,
  iconClassName,
  inputClassName,
  isDisabledClassName,
  closeButtonClassName,
  closeIconClassName,
}) => {
  return (
    <div
      className={classNames(styles.inputContainer, inputContainerClassName, {
        [isDisabledClassName || styles.isDisabled]: isDisabled,
      })}>
      <CalendarMonth
        className={classNames(styles.icon, iconClassName, { [isDisabledClassName || styles.isDisabled]: isDisabled })}
      />
      <ReactDatePicker
        selected={selectedDate}
        onChange={onChange}
        minDate={minDate}
        placeholderText={placeholderText}
        className={classNames(styles.input, 'date-picker-input', inputClassName, {
          [isDisabledClassName || styles.isDisabled]: isDisabled,
        })}
        dateFormat="dd/MM/yyyy"
        isClearable={false}
        showPopperArrow={false}
        disabled={isDisabled}
        onKeyDown={e => e.preventDefault()}
      />
      {selectedDate && !isDisabled && (
        <button className={classNames(styles.closeButton, closeButtonClassName)} onClick={() => onChange(null)}>
          <Close className={classNames(styles.closeIcon, closeIconClassName)} />
        </button>
      )}
    </div>
  )
}

export default DateInput
