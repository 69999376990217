import { useState } from 'react'

import { useLocation } from 'wouter'

import { OwnedBy } from '../../../generated/graphql'
import useNominationsModel from '../../../models/useNominationsModel'
import { toString } from '../../../utils/date'

import type { NominationData } from '../../../models/models'

export interface NominationCard {
  id: string
  title: string
  recordTime: string

  open: () => void
}

export interface NominationsViewModel {
  fetching: boolean
  nominations: NominationCard[]
  newNomination: () => void
  ownershipFilter: OwnedBy
  setOwnershipFilter: (ownership: OwnedBy) => void
}

export const OwnershipOptions = {
  [OwnedBy.Me]: 'Me',
  [OwnedBy.Tbo]: 'TBO',
  [OwnedBy.Everyone]: 'Anyone',
}

const DATE_FORMAT: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
}

function formatDate(value: Date) {
  return toString(value, DATE_FORMAT)
}

function createNominationCard(nomination: NominationData, navigate: () => void): NominationCard {
  return {
    id: nomination.id,
    title: nomination.id,
    recordTime: formatDate(nomination.recordTime),
    open: navigate,
  }
}

const useNominationsViewModel = (): NominationsViewModel => {
  const [ownershipFilter, setOwnershipFilter] = useState<OwnedBy>(OwnedBy.Me)

  const { fetching, nominations } = useNominationsModel(ownershipFilter)

  const [, navigate] = useLocation()

  const nominationCards: NominationCard[] = nominations.map(nomination => {
    const navigationHandler = () => navigate(`/nomination/${nomination.id}`)
    return createNominationCard(nomination, navigationHandler)
  })

  const newNomination = () => navigate('/nominations/new')

  return {
    fetching,
    newNomination,
    ownershipFilter,
    setOwnershipFilter,
    nominations: nominationCards,
  }
}

export default useNominationsViewModel
