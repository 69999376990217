import React from 'react'

import { GroupByType } from '../../ui/Table/TableColumnConfig'
import Selector from '../Header/Selector/Selector'

type GroupBySelectorProps = {
  groupBy: GroupByType 
  groupByKeys: GroupByType[] 
  handleGroupByChange: (key: GroupByType) => void 
}

const GroupBySelector: React.FC<GroupBySelectorProps> = ({ groupBy, groupByKeys, handleGroupByChange }) => {
  return (
    <Selector
      label="Group by"
      currentSelection={groupBy}
      options={groupByKeys}
      onChange={(key: string) => handleGroupByChange(key as GroupByType)}
      tooltipText="Grouping table columns"
    />
  )
}

export default GroupBySelector
