import { NominatableBoat } from '../../../Domain/Nomination'
import { showRiverLocation } from '../../../Domain/River'
import { HubLike, HubLikeId, LaneId } from '../../../generated/graphql'
import { formatBoat, formatDate, formatTime } from '../../../lib/formatters'

import { DepartureTimeFormValues, LaneSelectionFormValues, TowParametersFormValues } from './types'

export const buildLaneDescription = (
    laneSelection: LaneSelectionFormValues,
    lanes: Record<LaneId, string>,
    hubs: Record<HubLikeId, HubLike>
): string => {
    const { laneId, origin, destination } = laneSelection
    if (!laneId || !origin || !destination) {
        return 'Set a location'
    }
    const lane = lanes[laneId]
    const originLocation = showRiverLocation(hubs[origin].riverLocation)
    const destinationLocation = showRiverLocation(hubs[destination].riverLocation)

    return `${lane}: ${originLocation} to ${destinationLocation}`
}

export const buildVesselDescription = (
  boatId: string | undefined,
  hasTurnboat: boolean,
  boats: NominatableBoat[]
): string => {
  if (!boatId) return 'Vessel not set'
  const boatName = formatBoat(boatId, boats)
  return `${boatName}`
}

export const buildDepartureTimeDescription = (
  selectedDate: Date | null,
  time: DepartureTimeFormValues['time']
): string => {
  if (!selectedDate) return 'Departure date not set'
  const formattedDate = formatDate(selectedDate)
  if (!time) return formattedDate

  const timePart = formatTime(time)
  return `${formattedDate} at ${timePart}`
}

export const buildMaxDraftDescription = (feet: number | null, inch: number | null): string => {
    if ((feet === null && inch === null) || (feet === 0 && inch === 0)) {
        return 'Max Draft not set'
    }

    const feetDescription = feet !== null && feet > 0 ? `${feet} feet` : ''
    const inchDescription = inch !== null && inch > 0 ? `${inch} inches` : ''

    const totalInches = (feet ?? 0) * 12 + (inch ?? 0)

    return `${[feetDescription, inchDescription].filter(Boolean).join(' ')} (${totalInches} inches)`
}

export const buildIncludeTBOsDescription = (includeTBOs: number[]): string =>
    includeTBOs.length === 0 ? 'No TBOs included' : `Included TBOs: ${includeTBOs.join(', ')}`

export const buildTowParametersDescription = (towParameters: TowParametersFormValues) => {
  const { numberOfLoaded, numberOfEmpties, numberOfStrings, goal } = towParameters

  const numberOfStringsDescription =
    numberOfStrings ? `Number of strings: ${numberOfStrings}` : 'Number of strings not set'
  const goalDescription = goal ? `goal: ${goal}` : 'Goal not set'

  const loadedDescription = `Loaded barges: ${numberOfLoaded ?? 0}`
  const emptyDescription = `Empty barges: ${numberOfEmpties ?? 0}`

  return [loadedDescription, emptyDescription, numberOfStringsDescription, goalDescription]
}
