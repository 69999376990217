import React, { FC } from 'react'

import { FormikErrors } from 'formik'
import { mergeRight } from 'ramda'

import { GoalId } from '../../../../generated/graphql'
import { TowParametersFormValues } from '../types'

import { GoalSelection } from './FormComponents'

type OperationalGoalFormProps = {
    data: TowParametersFormValues
    onChange: (config: TowParametersFormValues) => void
    errors?: FormikErrors<TowParametersFormValues>
    goals: Record<GoalId, { label: string }>}

const OperationalGoalForm: FC<OperationalGoalFormProps> = ({ data, onChange, errors, goals }) => {

    const handleGoalSelect = (goal: GoalId) => {
        const updatedData = mergeRight(data, { goal })
        onChange(updatedData)
    }

    return (
        <div>
            <GoalSelection
                goals={goals}
                selectedGoal={data.goal}
                onSelect={handleGoalSelect}
                warnings={errors?.goal}
            />
        </div>
    )
}

export default OperationalGoalForm
