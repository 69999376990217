import { Filter } from '../../../Domain/BargeFilters'
import { Toggle } from '../../../ui/Toggle/Toggle'

import styles from './LaneFilters.module.scss'

type Props = {
  filters: Filter
  setFilters: (filters: Filter) => void
  isEnabled: boolean
  readOnly: boolean
}
export const ExclusionFilters = ({ filters, setFilters, isEnabled, readOnly }: Props) => (
  <>
    <label className={`${styles.label} ${readOnly ? styles.disabledLabel : ''}`}>
      <span>Tanks</span>
      <Toggle
        enabled={isEnabled && filters.excludeTanks}
        onChange={() => setFilters({ ...filters, excludeTanks: !filters.excludeTanks })}
      />
    </label>
    <label className={`${styles.label} ${readOnly ? styles.disabledLabel : ''}`}>
      <span>Open hopper</span>
      <Toggle
        enabled={isEnabled && filters.excludeHoppers}
        onChange={() => setFilters({ ...filters, excludeHoppers: !filters.excludeHoppers })}
      />
    </label>
    <label className={`${styles.label} ${readOnly ? styles.disabledLabel : ''}`}>
      <span>Having TBO info</span>
      <Toggle
        enabled={isEnabled && filters.excludeTboInfo}
        onChange={() => setFilters({ ...filters, excludeTboInfo: !filters.excludeTboInfo })}
      />
    </label>
    <label className={`${styles.label} ${readOnly ? styles.disabledLabel : ''}`}>
      <span>Placed to load</span>
      <Toggle
        enabled={isEnabled && filters.excludePlacedToLoad}
        onChange={() => setFilters({ ...filters, excludePlacedToLoad: !filters.excludePlacedToLoad })}
      />
    </label>
    <label className={`${styles.label} ${readOnly ? styles.disabledLabel : ''}`}>
      <span>Shuttle moves</span>
      <Toggle
        enabled={isEnabled && filters.excludeShuttleMoves}
        onChange={() => setFilters({ ...filters, excludeShuttleMoves: !filters.excludeShuttleMoves })}
      />
    </label>
  </>
)
