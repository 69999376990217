import { useState } from 'react'

import { Popover, Transition } from '@headlessui/react'
import { DragHandle, SettingsBackupRestore, ArrowDropDown, ArrowDropUp } from '@mui/icons-material'
import Tooltip from '@mui/material/Tooltip/Tooltip'
import classNames from 'classnames'
import { ReactSortable } from 'react-sortablejs'

import { Checkbox } from '../Checkbox/Checkbox'

import styles from './TableColumnConfig.module.scss'

type Config = {
  hidden: string[]
  columns: Array<{ id: string; name: string; defaultOrder: number }>
}

type TableColumnConfigProps = {
  config: Config
  defaultColumns: Config['columns']
  defaultHidden: string[]
  handleApply: (config: Config) => void
}

export enum GroupByType {
  DropOffFacility = 'Drop-off',
  PickupFacility = 'Pickup',
  CurrentLocation = 'Current Location',
  Destination = 'Destination',
  BargeType = 'Type',
  PickupType = 'Pickup Type',
  HullType = 'Hull Type',
  TripStatus = 'Trip Status',
  CurrentLocationAndFleet='Fleet',
}

export const groupByKeys: Array<GroupByType> = [
  GroupByType.DropOffFacility,
  GroupByType.PickupFacility,
  GroupByType.CurrentLocation,
  GroupByType.Destination,
  GroupByType.BargeType,
  GroupByType.PickupType,
  GroupByType.HullType,
  GroupByType.TripStatus,
  GroupByType.CurrentLocationAndFleet,
]

export enum ColorByType {
  Hot = 'Hot',
  Empty = 'Empty',
  RiverBasin = 'River Basin',
  None = 'None',
}

export const colorByOptions: ColorByType[] = [
  ColorByType.Hot,
  ColorByType.Empty,
  ColorByType.RiverBasin,
  ColorByType.None,
]

export const getColorClass = (colorType: ColorByType): string => {
  return `color-${colorType.replace(' ', '')}`
}

export function TableColumnConfig({ config, defaultHidden, defaultColumns, handleApply }: TableColumnConfigProps) {
  const [list, setList] = useState(config.columns)
  const [hidden, setHidden] = useState<string[]>(config.hidden)

  return (
    <Popover>
      {({ open, close }) => (
        <Tooltip title="Configure table columns" placement="top">
          <div className={styles.container}>
            <Popover.Button className={styles.button}>
              <span>config. table</span>
              {open ? <ArrowDropUp /> : <ArrowDropDown />}
            </Popover.Button>
            <Transition show={open}>
              <Popover.Panel className={classNames(styles.panel, { [styles.open]: open })}>
                <h2 className={styles.heading}>Configure table columns</h2>
                <ReactSortable
                  tag="ol"
                  className={styles.list}
                  list={list}
                  setList={xs => {
                    setList(
                      xs
                        .map((x, index) => ({ ...x, defaultOrder: index }))
                        .sort((a, b) => {
                          if (a.defaultOrder === b.defaultOrder) return 0
                          return a.defaultOrder > b.defaultOrder ? 1 : -1
                        })
                    )
                  }}
                  handle={`.${styles.dragHandle}`}>
                  {list.map(column => (
                    <li key={column.id} className={styles.item}>
                      <span className={styles.itemText}>
                        <Checkbox
                          label={column.name}
                          checked={!hidden.includes(column.id)}
                          onChange={isVisible => {
                            setHidden(hidden.filter(id => id !== column.id).concat(isVisible ? [] : [column.id]))
                          }}
                        />
                      </span>
                      <button type="button" className={styles.dragHandle}>
                        <span className="sr-only">Drag to reorder columns</span>
                        <DragHandle />
                      </button>
                    </li>
                  ))}
                </ReactSortable>
                <button
                  type="button"
                  className={styles.applyBtn}
                  onClick={() => {
                    handleApply({ columns: list, hidden })
                    close()
                  }}>
                  Apply
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setList(defaultColumns)
                    setHidden(defaultHidden)
                  }}
                  className={styles.resetColumnOrderBtn}>
                  Reset columns
                  <SettingsBackupRestore />
                </button>
              </Popover.Panel>
            </Transition>
          </div>
        </Tooltip>
      )}
    </Popover>
  )
}
