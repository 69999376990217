import React, { FC, useState } from 'react'

import * as R from 'ramda'

import { BargeListing } from '../../../components/BargeListing/BargeListing'
import { LoadingSpinner } from '../../../ui/Spinner/Spinner'

import styles from './styles.module.scss'
import { BargePoolViewModel, TabOptions } from './types'

const BargePoolBlock: FC<BargePoolViewModel> = ({
  lane,
  origin,
  destination,
  barges,
  pinnedBarges,
  setPinnedBarges,
  excludedBarges,
  setExcludedBarges,
  isFetching,
}) => {
  const [activeTab, setActiveTab] = useState<TabOptions>(TabOptions.BargePool)

  const isLaneAndHubsSelected = !!(lane && origin && destination)

  const handleBargePinning = (bargeIds: string[]) => {
    const updatedExcludedBarges = R.without(bargeIds, excludedBarges)
    setExcludedBarges(updatedExcludedBarges)

    setPinnedBarges(bargeIds)
  }

  const handleBargeExcluded = (bargeIds: string[]) => {
    const updatedPinnedBarges = R.without(bargeIds, pinnedBarges)
    setPinnedBarges(updatedPinnedBarges)

    setExcludedBarges(bargeIds)
  }


  const filteredBarges = R.cond([
    [R.equals(TabOptions.BargePool), () => barges],
    [R.equals(TabOptions.Preselected), () => R.filter(barge => R.includes(barge.barge.id, pinnedBarges), barges)],
    [R.equals(TabOptions.Excluded), () => R.filter(barge => R.includes(barge.barge.id, excludedBarges), barges)]
  ])(activeTab)

  if (isFetching) {
    return (
      <div className={styles.centeredSpinner}>
        <LoadingSpinner isFullScreen={false} />
      </div>
    )
  }

  return (
    <div className={styles.bargePoolRoot}>
      <div className={styles.bargePoolTitle}>Barge pool</div>

      <div className={styles.tabNavigation}>
        {Object.values(TabOptions).map((tab) => (
          <button
            key={tab}
            onClick={() => setActiveTab(tab)}
            className={`${styles.tabButton} ${activeTab === tab ? styles.activeTab : ''}`}
          >
            {tab}
          </button>
        ))}
      </div>

      <div className={`${styles.bargePoolWrapper} ${styles.tableContainer}`}>
        <BargeListing
          lane={lane}
          barges={filteredBarges}
          isSelectable={() => isLaneAndHubsSelected}
          pinnedBarges={pinnedBarges}
          setPinnedBarges={handleBargePinning}
          excludedBarges={excludedBarges}
          setExcludedBarges={handleBargeExcluded}
        />
      </div>
    </div>
  )
}

export default BargePoolBlock
