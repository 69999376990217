import React, { type FC, useEffect } from 'react'

import * as R from 'ramda'
import { Link } from 'wouter'

import { PrimaryMenu } from '../../../components/Header/PrimaryMenu/PrimaryMenu'
import { Layout } from '../../../ui/Layout/Layout'
import { LoadingSpinner } from '../../../ui/Spinner/Spinner'

import BargeListBlock from './BargeListBlock'
import JourneyBlock from './JourneyBlock'
import NominationSummaryBlock from './NominationSummaryBlock'
import NominationVersionSummary from './NominationVersionSummary'
import styles from './styles.module.scss'
import useNominationDetailsViewModel, { isFetched, isFetching, type VersionLink } from './viewModel'

type NominationDetailsPageProps = {
  readonly id: string
  readonly version: string | null
}

const renderVersionLink = (version: VersionLink) => {
  return (
    <Link to={version.path} key={version.id}>
      {version.recordTime}
    </Link>
  )
}

const NominationDetailsPage: FC<NominationDetailsPageProps> = ({ id, version }) => {
  const viewModel = useNominationDetailsViewModel(id, version)

  useEffect(() => {
    if (isFetched(viewModel) && !version) {
      viewModel.navigation.redirectToLatest()
    }

    if (isFetched(viewModel) && !viewModel.selectedVersion) {
      viewModel.navigation.redirectToLatest()
    }
  }, [viewModel, version])

  if (isFetching(viewModel)) {
    return <LoadingSpinner isFullScreen />
  }

  const { navigation, nominationSummary, selectedVersion } = viewModel

  const versionSelectors = R.map(renderVersionLink, navigation.links)

  return (
    <Layout
      primaryMenu={({ isDrawerOpen, setIsDrawerOpen }) => (
        <PrimaryMenu isDrawerOpen={isDrawerOpen} setDrawerOpen={setIsDrawerOpen}>
          <nav className={styles.nav}>{versionSelectors}</nav>
        </PrimaryMenu>
      )}>
      <NominationSummaryBlock summary={nominationSummary} />
      {selectedVersion && (
        <>
          <NominationVersionSummary summary={selectedVersion.summary} />
          <JourneyBlock journey={selectedVersion.journey} />
          <BargeListBlock
            nominatedBarges={selectedVersion.nominatedBarges}
            bargeNamesCallback={selectedVersion.bargeNamesCallback}
          />
        </>
      )}
    </Layout>
  )
}

export default NominationDetailsPage
