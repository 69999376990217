import React, { FC } from 'react'

import { FormikErrors } from 'formik'

import { OverviewBarge } from '../../../../Domain/Barge'
import { TowParametersFormValues } from '../types'

import OperationalGoalForm from './OperationalGoalForm'
import TowParametersForm from './TowParametersForm'


type TowParametersSelectionFormProps = {
    data: TowParametersFormValues
    onChange: (config: TowParametersFormValues) => void
    errors?: FormikErrors<TowParametersFormValues>
    barges: OverviewBarge[]
    goals: Record<string, { label: string }>
}


const TowParametersSelectionForm: FC<TowParametersSelectionFormProps> = ({
    data,
    onChange,
    errors,
    barges,
    goals
}) => {
    return (
        <div>
            <TowParametersForm
                data={data}
                setData={onChange}
                errors={errors}
                barges={barges}
            />

            <OperationalGoalForm
                data={data}
                onChange={onChange}
                errors={errors}
                goals={goals}
            />
        </div>
    )
}

export default TowParametersSelectionForm
