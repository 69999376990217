import React from 'react'

import { AccessTime, Close } from '@mui/icons-material'
import classNames from 'classnames'
import { PatternFormat, NumberFormatValues } from 'react-number-format'

import styles from './styles.module.scss'

export type Time = {
  hours: number
  minutes: number
}

type TimeInputProps = {
  handleChange: (val: Time | null) => void
  isDisabled?: boolean
  value?: Time | null
  isDateSelected: boolean
  placeholderText?: string
  inputContainerClassName?: string
  iconClassName?: string
  timeInputClassName?: string
  disabledClassName?: string
  clearButtonClassName?: string
  closeIconClassName?: string
}

const PATTERN = ['[0-2]', '[0-9]', '[0-5]', '[0-9]']

function isAllowed({ value }: NumberFormatValues) {
  const pat = PATTERN.slice(0, value.length)
  if (pat.length > 1 && value.startsWith('2')) {
    pat[1] = '[0-3]'
  }
  return value.match(new RegExp(pat.join(''))) !== null
}

const parseTime = (formattedValue: string): { hours: number; minutes: number } | null => {
  if (formattedValue.length !== 5 || !formattedValue.includes(':')) return null

  const [hoursStr, minutesStr] = formattedValue.split(':')

  const hours = Number(hoursStr)
  const minutes = Number(minutesStr)

  if (
    !Number.isInteger(hours) ||
    !Number.isInteger(minutes) ||
    hours < 0 ||
    hours > 23 ||
    minutes < 0 ||
    minutes > 59
  ) {
    return null
  }
  return { hours, minutes }
}


const formatTime = (time: Time | null): string => {
  if (!time) return ''
  const hours = String(time.hours).padStart(2, '0')
  const minutes = String(time.minutes).padStart(2, '0')
  return `${hours}:${minutes}`
}

export const TimeInput: React.FC<TimeInputProps> = ({
  handleChange,
  isDisabled = false,
  value = null,
  isDateSelected,
  placeholderText = 'HH:MM',
  inputContainerClassName,
  iconClassName,
  timeInputClassName,
  disabledClassName,
  clearButtonClassName,
  closeIconClassName,
}) => {
  const timeInputDisabled = isDisabled || !isDateSelected
  const formattedTime = formatTime(value)

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value

    const parsedTime = parseTime(inputValue)
    handleChange(parsedTime)
  }

  const showClearButton = formattedTime !== '' && !isDisabled

  return (
    <div
      className={classNames(styles.inputContainer, inputContainerClassName, {
        [styles.isDisabled]: timeInputDisabled,
      })}>
      <AccessTime
        className={classNames(styles.icon, iconClassName, {
          [styles.activeIcon]: !!formattedTime && isDateSelected,
          [styles.isDisabled]: timeInputDisabled,
        })}
      />
      <PatternFormat
        value={formattedTime}
        disabled={timeInputDisabled}
        onChange={handleInputChange}
        className={classNames(styles.timeInput, timeInputClassName, {
          [disabledClassName || styles.isDisabled]: timeInputDisabled,
        })}
        format="##:##"
        mask="_"
        placeholder={placeholderText}
        isAllowed={isAllowed}
      />
      {showClearButton && (
        <button className={classNames(styles.closeButton, clearButtonClassName)} onClick={() => handleChange(null)}>
          <Close className={classNames(styles.closeIcon, closeIconClassName)} />
        </button>
      )}
    </div>
  )
}

export default TimeInput
