import { useMemo, useState } from 'react'

import * as R from 'ramda'
import { object as YupObject, string as YupString, number as YupNumber } from 'yup'

import { fromBargesQuery, isEmpty, minNumberOfRakes, OverviewBarge } from '../../../Domain/Barge'
import { } from '../../../Domain/Hub'
import { GoalId, HullType, useLaneBargesQuery } from '../../../generated/graphql'
import { useSettingsContext } from '../../../providers/SettingsProvider'

import { createFormData } from './form'
import { createStageNavigation } from './navigation'
import { buildDepartureTimeDescription, buildIncludeTBOsDescription, buildLaneDescription, buildMaxDraftDescription, buildTowParametersDescription, buildVesselDescription } from './selectors'
import {
  DepartureTimeFormValues,
  isDepartureTimeSelectionStage,
  isLaneSelectionStage,
  isPoolFiltersSelectionStage,
  isTowParametersSelectionStage,
  isVesselSelectionStage,
  LaneSelectionFormValues,
  NominationFormViewModel,
  NominationStage,
  PoolFiltersSelectionFormValues,
  StageData,
  TowParametersFormValues,
  VesselSelectionFormValues,
} from './types'
import { filterDestinations, filterOrigins, toBargePoolRequestParameters } from './utilities'

const MINIMAL_NR_OF_BARGES = 2

export const collectTotals = (data: TowParametersFormValues, barges: OverviewBarge[]) => {
  const totalNumberOfBarges = R.length(barges)

  const totals = R.reduce(
    (acc, barge) => ({
      empties: acc.empties + (isEmpty(barge) ? 1 : 0),
      rakes: acc.rakes + (barge.barge.hullType === HullType.Rake ? 1 : 0),
    }),
    { empties: 0, rakes: 0 },
    barges
  )

  const totalNumberOfLoaded = totalNumberOfBarges - totals.empties
  const total = Math.max(data.numberOfLoaded, MINIMAL_NR_OF_BARGES) + data.numberOfEmpties

  const remaining = {
    numberOfLoaded: Math.max(totalNumberOfLoaded - data.numberOfLoaded, 0),
    numberOfEmpties: Math.max(totals.empties - data.numberOfEmpties, 0),
    numberOfRakes: Math.max(totals.rakes - minNumberOfRakes(data.numberOfLoaded + data.numberOfEmpties), 0),
    numberOfBarges: Math.max(totalNumberOfBarges - total, 0),
  }

  return {
    totalNumberOfLoaded,
    totalNumberOfEmpties: totals.empties,
    remaining,
    totalNumberOfBarges,
  }
}

export const laneSelectionInitialValues: LaneSelectionFormValues = {
  laneId: undefined,
  origin: undefined,
  destination: undefined,
}

export const laneSelectionValidationSchema = YupObject().shape(
  {
    laneId: YupString().required('Required'),
    origin: YupString().required('Required'),
    destination: YupString().required('Required'),
  },
  []
)

export const includeTBOsValidationSchema = YupObject().shape({
  tboInput: YupNumber()
    .positive('TBO number must be positive')
    .integer('TBO number must be an integer'),
})

export const towParametersValidationSchema = YupObject().shape({
  numberOfLoaded: YupNumber().required('Required').positive('Must be positive'),
  numberOfEmpties: YupNumber().required('Required').positive('Must be positive'),
  numberOfStrings: YupNumber().nullable(),
  goal: YupString().nullable().oneOf(Object.values(GoalId)),
})

type FormState = {
  currentStage: NominationStage
  laneSelectionForm: LaneSelectionFormValues
  vesselSelectionForm: VesselSelectionFormValues
  departureTimeSelectionForm: DepartureTimeFormValues
  poolFiltersSelectionForm: PoolFiltersSelectionFormValues
  towParametersSelectionForm: TowParametersFormValues
}

const initialNavigationState = {
  stage: NominationStage.LaneSelection,
}

const initialFormState: FormState = {
  currentStage: NominationStage.LaneSelection,
  laneSelectionForm: {
    laneId: undefined,
    origin: undefined,
    destination: undefined,
  },
  vesselSelectionForm: {
    boatId: undefined,
    hasTurnboat: false,
  },
  departureTimeSelectionForm: {
    selectedDate: null,
    time: null,
  },
  poolFiltersSelectionForm: {
    feet: null,
    inch: null,
    nominatedBarges: false,
    tanks: false,
    openHopper: false,
    havingTboInfo: true,
    placedToLoad: false,
    shuttleMoves: true,
    includeTBOs: [],
  },
  towParametersSelectionForm: {
    numberOfLoaded: 0,
    numberOfEmpties: 0,
    numberOfStrings: null,
    goal: null,
  },
}

const useNominationFormViewModel = (): NominationFormViewModel => {
  const [stage, setStage] = useState(initialNavigationState.stage)

  // const [laneSelectionForm, changeLaneSelectionForm] = useState(laneSelectionInitialValues)
  // const [vesselSelectionForm, changeVesselSelectionForm] = useState({})
  // const [departureTimeSelectionForm, changeDepartureTimeSelectionForm] = useState({})
  // const [towParametersSelectionForm, changeTowParametersSelectionForm] = useState({})

  const [formState, setFormState] = useState<FormState>(initialFormState)

  const { lanes, hubs, boats, goals } = useSettingsContext()

  const bargePoolParameters = toBargePoolRequestParameters(
    formState.laneSelectionForm,
    formState.departureTimeSelectionForm,
    formState.poolFiltersSelectionForm
  )

  const [{ data: bargesInPool, fetching: isFetchingBargePool }] = useLaneBargesQuery({
    variables: bargePoolParameters,
  })
  const [pinnedBarges, setPinnedBarges] = useState<string[]>([])
  const [excludedBarges, setExcludedBarges] = useState<string[]>([])

  const bargePool = useMemo(
    () => ({
      lane: formState.laneSelectionForm.laneId,
      origin: formState.laneSelectionForm.origin,
      destination: formState.laneSelectionForm.destination,
      barges: bargesInPool ? fromBargesQuery(bargesInPool.lanes[0].barges) : [],
      isFetching: isFetchingBargePool,
      pinnedBarges,
      setPinnedBarges,
      excludedBarges,
      setExcludedBarges,
    }),
    [formState, bargesInPool, isFetchingBargePool, pinnedBarges, excludedBarges]
  )

  const updateLaneSelectionForm = (values: LaneSelectionFormValues) => {
    setFormState(prev => ({
      ...prev,
      laneSelectionForm: values,
    }))
  }

  const updateVesselSelectionForm = (values: VesselSelectionFormValues) => {
    setFormState(prev => ({
      ...prev,
      vesselSelectionForm: values,
    }))
  }

  const updatePoolFiltersForm = (values: PoolFiltersSelectionFormValues) => {
    setFormState(prev => ({
      ...prev,
      poolFiltersSelectionForm: {
        ...prev.poolFiltersSelectionForm,
        ...values,
      },
    }))
  }

  const updateTowParametersSelectionForm = (values: TowParametersFormValues) => {
    setFormState(prev => {
      const updatedState = {
        ...prev,
        towParametersSelectionForm: {
          ...prev.towParametersSelectionForm,
          ...values,
        },
      }
      return updatedState
    })
  }

  const currentStage = {
    stage,
  }

  const laneSelectionInitParams = useMemo(() => {
    const { laneId, origin } = formState.laneSelectionForm
    const origins = filterOrigins(laneId, hubs)
    const destinations = filterDestinations(laneId, origin, hubs)
    return {
      lanes,
      origins,
      destinations,
    }
  }, [formState, lanes, hubs])

  const vesselSelectionInitParams = useMemo(
    () => ({
      boats,
    }),
    [boats]
  )

  const towParametersInitParams = useMemo(() => {
    return {
      goals,
      currentGoal: formState.towParametersSelectionForm.goal,
      barges: bargePool.barges,
    }
  }, [formState.towParametersSelectionForm.goal, goals, bargePool.barges])

  const stages: Record<NominationStage, StageData<any, any, any>> = {
    [NominationStage.LaneSelection]: {
      isSelected: isLaneSelectionStage(currentStage),
      form: createFormData(formState.laneSelectionForm, updateLaneSelectionForm),
      summary: { description: buildLaneDescription(formState.laneSelectionForm, lanes, hubs) },
      initParameters: laneSelectionInitParams,
      actions: createStageNavigation(NominationStage.LaneSelection, setStage),
    },
    [NominationStage.VesselSelection]: {
      isSelected: isVesselSelectionStage(currentStage),
      form: createFormData(formState.vesselSelectionForm, updateVesselSelectionForm),
      summary: {
        description: buildVesselDescription(
          formState.vesselSelectionForm.boatId,
          formState.vesselSelectionForm.hasTurnboat,
          boats
        ),
      },
      initParameters: vesselSelectionInitParams,
      actions: createStageNavigation(NominationStage.VesselSelection, setStage),
    },
    [NominationStage.DepartureTimeSelection]: {
      isSelected: isDepartureTimeSelectionStage(currentStage),
      form: createFormData(formState.departureTimeSelectionForm, values =>
        setFormState(prev => ({ ...prev, departureTimeSelectionForm: values }))
      ),
      summary: {
        description: buildDepartureTimeDescription(
          formState.departureTimeSelectionForm.selectedDate,
          formState.departureTimeSelectionForm.time
        ),
      },
      actions: createStageNavigation(NominationStage.DepartureTimeSelection, setStage),
    },
    [NominationStage.PoolFiltersSelection]: {
      isSelected: isPoolFiltersSelectionStage(currentStage),
      form: createFormData(formState.poolFiltersSelectionForm, updatePoolFiltersForm),
      summary: {
        description: [
          buildMaxDraftDescription(formState.poolFiltersSelectionForm.feet, formState.poolFiltersSelectionForm.inch),
          buildIncludeTBOsDescription(formState.poolFiltersSelectionForm.includeTBOs || []),
        ],
      },
      actions: createStageNavigation(NominationStage.PoolFiltersSelection, setStage),
    },
    [NominationStage.TowParametersSelection]: {
      isSelected: isTowParametersSelectionStage(currentStage),
      form: createFormData(formState.towParametersSelectionForm, updateTowParametersSelectionForm),
      summary: {
        description: buildTowParametersDescription(formState.towParametersSelectionForm),
      },
      initParameters: towParametersInitParams,
      actions: createStageNavigation(NominationStage.TowParametersSelection, setStage),
    },
  }

  return {
    currentStage,
    stages,
    bargePool,
    goals
  }
}

export default useNominationFormViewModel
