import React, { FC } from 'react'

import { DirectionsBoat } from '@mui/icons-material'
import { Tooltip } from '@mui/material'

import styles from '../styles.module.scss'

type VesselSelectionSummaryProps = {
  description: string
  hasTurnboat: boolean
}

const VesselSelectionSummary: FC<VesselSelectionSummaryProps> = ({ description, hasTurnboat }) => {
  const renderTooltipMessage = (isActive: boolean, name: string) => {
    return `${name}: ${isActive ? 'Active' : 'Inactive'}`
  }

  return (
    <div>
      <p>{description}</p>
      <Tooltip title={renderTooltipMessage(hasTurnboat, 'Turnboat')}>
        <DirectionsBoat className={`${styles.icon} ${hasTurnboat ? styles.active : styles.inactive}`} />
      </Tooltip>
    </div>
  )
}

export default VesselSelectionSummary
