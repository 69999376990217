import { useCallback, useMemo, useState } from 'react'

import { Block, Check, ContentCopy, Grading, PushPin } from '@mui/icons-material'
import { Tooltip } from '@mui/material'
import classnames from 'classnames'
import * as R from 'ramda'
import { match } from 'ts-pattern'

import { ELLIPSIS } from '../../constants/constants'
import { isNominatedBargeWithReview, NominatedBarge, NominatedBargeWithReview } from '../../Domain/Nomination'
import { showRiverLocation } from '../../Domain/River'
import { isTripStatus, showTripStatus } from '../../Domain/Trip'
import { FacilityLocation, LoadStatus, PickupType, RiverLocationLite } from '../../generated/graphql'
import { define, Sortable, useSorting, SortingState, Column } from '../../lib/Column'
import { Nullable, map as mapNullable } from '../../lib/Nullable'
import { useBargeSelectionBridge } from '../../providers/BargeSelectionBridge'
import { useSettingsContext } from '../../providers/SettingsProvider'
import { Cross } from '../../ui/Cross/Cross'
import { SortableColumn } from '../../ui/Table/SortColumn/SortColumn'
import { TB, TBHead, TBBody, TBR, HeaderCell } from '../../ui/Table/Table'
import { TableCell } from '../../ui/Table/TableCell'
import { GroupByType } from '../../ui/Table/TableColumnConfig'
import { Ticker } from '../../ui/Ticker/Ticker'

import { RiskLevelEntry } from './RiskLevelEntry'
import styles from './Table.module.scss'

export const pickupTypeLabels = {
  [PickupType.Simple]: 'External',
  [PickupType.Consolidated]: 'Hub',
}

const NOT_AVAILABLE = 'n/a'

class NominatedBargeStringColumn extends Column<NominatedBarge | NominatedBargeWithReview, Nullable<string>> {}

class TBOInfoColumn extends NominatedBargeStringColumn {
  sort(rowA: NominatedBarge | NominatedBargeWithReview, rowB: NominatedBarge | NominatedBargeWithReview): -1 | 0 | 1 {
    if (isNominatedBargeWithReview(rowA) && isNominatedBargeWithReview(rowB)) {
      if (rowA.review.receivedTBO || rowB.review.receivedTBO) {
        if (rowA.review.receivedTBO && rowB.review.receivedTBO) {
          return Column.prototype.sort.call(this, rowA, rowB)
        }
        return rowA.review.receivedTBO ? -1 : 1
      }
    }

    return Column.prototype.sort.call(this, rowA, rowB)
  }
}

class LocationColumn extends NominatedBargeStringColumn {
  sortLocation(a: RiverLocationLite, b: RiverLocationLite): -1 | 0 | 1 {
    if (a.code === b.code) {
      if (a.mileagePoint === b.mileagePoint) return 0
      return a.mileagePoint > b.mileagePoint ? 1 : -1
    }

    return a.code > b.code ? 1 : -1
  }

  sortFacilityLocation(a: FacilityLocation, b: FacilityLocation): -1 | 0 | 1 {
    if (a.code === b.code) {
      if (a.mileagePoint === b.mileagePoint) return 0
      return a.mileagePoint > b.mileagePoint ? 1 : -1
    }

    return a.code > b.code ? 1 : -1
  }
}

class CurrentLocationColumn extends LocationColumn {
  sort(rowA: NominatedBarge | NominatedBargeWithReview, rowB: NominatedBarge | NominatedBargeWithReview): -1 | 0 | 1 {
    return rowA.currentLocation && rowB.currentLocation
      ? this.sortLocation(rowA.currentLocation, rowB.currentLocation)
      : Column.prototype.sort.call(this, rowA, rowB)
  }
}

class DestinationColumn extends LocationColumn {
  sort(rowA: NominatedBarge | NominatedBargeWithReview, rowB: NominatedBarge | NominatedBargeWithReview): -1 | 0 | 1 {
    return rowA.destination && rowB.destination
      ? this.sortLocation(rowA.destination, rowB.destination)
      : Column.prototype.sort.call(this, rowA, rowB)
  }
}

class DropOffFacilityColumn extends LocationColumn {
  sort(rowA: NominatedBarge | NominatedBargeWithReview, rowB: NominatedBarge | NominatedBargeWithReview): -1 | 0 | 1 {
    return rowA.dropOffFacility && rowB.dropOffFacility
      ? this.sortFacilityLocation(rowA.dropOffFacility, rowB.dropOffFacility)
      : Column.prototype.sort.call(this, rowA, rowB)
  }
}

class PickupFacilityColumn extends LocationColumn {
  sort(rowA: NominatedBarge | NominatedBargeWithReview, rowB: NominatedBarge | NominatedBargeWithReview): -1 | 0 | 1 {
    return rowA.pickupFacility && rowB.pickupFacility
      ? this.sortFacilityLocation(rowA.pickupFacility, rowB.pickupFacility)
      : Column.prototype.sort.call(this, rowA, rowB)
  }
}

const col = define<NominatedBarge | NominatedBargeWithReview>()

export const columns = {
  isAvailable: col.boolean('Available', _ => isNominatedBargeWithReview(_) && _.review.leftTheBargePool, {
    format: leftThePool => `${!leftThePool}`,
  }),
  tboInfo: new TBOInfoColumn('TBO', _ =>
    isNominatedBargeWithReview(_) ? _.review.receivedTBO ?? _.towBuildOrder?.latestInfo : _.towBuildOrder?.latestInfo
  ),
  inOtherNomination: col.string('Nominated', _ =>
    isNominatedBargeWithReview(_) ? _.review.otherNominations?.join('\n') : undefined
  ),
  name: col.string('Name', _ => _.name),
  isAtRisk: col.string('At Risk', _ =>
    isNominatedBargeWithReview(_) ? _.riskLevel || _.review.riskLevel : _.riskLevel
  ),
  cargo: col.string('Cargo', _ => _.cargo),
  hullType: col.string('Hull Type', _ => _.hullType),
  type: col.string('Type', _ => _.type),
  pickupType: col.string('Pickup Type', _ => pickupTypeLabels[_.pickupType]),
  tripStatus: col.string('Trip Status', _ => _.tripStatus),
  destination: new DestinationColumn('Destination', _ => mapNullable(_.destination, showRiverLocation)),
  pickUp: new PickupFacilityColumn('Pickup', _ => mapNullable(_.pickupFacility, showRiverLocation)),
  dropOff: new DropOffFacilityColumn('Drop-off', _ => mapNullable(_.dropOffFacility, showRiverLocation)),
  currentLocation: new CurrentLocationColumn('Current Location', _ =>
    mapNullable(_.currentLocation, showRiverLocation)
  ),
  fleet: col.string('Fleet', _ => _.fleet?.name),
}

type ColumnKey = keyof typeof columns
export const columnWithReviewKeys: ColumnKey[] = [
  'isAvailable',
  'tboInfo',
  'inOtherNomination',
  'name',
  'isAtRisk',
  'cargo',
  'currentLocation',
  'destination',
  'hullType',
  'tripStatus',
  'pickUp',
  'dropOff',
  'pickupType',
]

export const columnKeys: ColumnKey[] = [
  'tboInfo',
  'name',
  'isAtRisk',
  'cargo',
  'currentLocation',
  'destination',
  'hullType',
  'type',
  'tripStatus',
  'pickUp',
  'dropOff',
  'pickupType',
  'fleet',
]

export type Theme = {
  columns: Record<ColumnKey, string | undefined>
}

export const defaultTheme: Theme = {
  columns: {
    isAvailable: styles.cell,
    tboInfo: styles.tboCell,
    inOtherNomination: styles.cell,
    name: styles.nameCell,
    isAtRisk: styles.isAtRiskCell,
    cargo: undefined,
    destination: styles.cell,
    currentLocation: styles.cell,
    hullType: styles.hullTypeCell,
    tripStatus: styles.cell,
    pickUp: styles.locationCell,
    dropOff: styles.locationCell,
    pickupType: styles.cell,
    type: styles.cell,
    fleet: styles.cell,
  },
}

export function getDataByColumns(barges: Array<NominatedBarge | NominatedBargeWithReview>) {
  const keys = isNominatedBargeWithReview(barges[0]) ? columnWithReviewKeys : columnKeys

  return barges.map(barge =>
    keys.reduce((acc: Record<string, string | undefined | null>, key) => {
      const column = columns[key]
      acc[column.label] = column.format(barge) ?? ELLIPSIS
      return acc
    }, {})
  )
}

const formatTripStatus = (status: string): string => {
  return status.replace(/([a-z])([A-Z])/g, '$1 $2')
}

function formatLocationAndFleet(currentLocation: string | null, fleetName: string | null): string {
  return currentLocation && fleetName
    ? `${currentLocation} - ${fleetName}`
    : currentLocation || fleetName || NOT_AVAILABLE
}

type ExtractorFunction = (barge: NominatedBarge) => string
const groupByFunctions: Record<GroupByType, ExtractorFunction> = {
  [GroupByType.DropOffFacility]: (barge: NominatedBarge) => showRiverLocation(barge.dropOffFacility) || NOT_AVAILABLE,
  [GroupByType.PickupFacility]: (barge: NominatedBarge) => showRiverLocation(barge.pickupFacility) || NOT_AVAILABLE,
  [GroupByType.CurrentLocation]: (barge: NominatedBarge) =>
    barge.currentLocation ? showRiverLocation(barge.currentLocation) : NOT_AVAILABLE,
  [GroupByType.Destination]: (barge: NominatedBarge) =>
    barge.destination ? showRiverLocation(barge.destination) : NOT_AVAILABLE,
  [GroupByType.BargeType]: (barge: NominatedBarge) => barge.type || NOT_AVAILABLE,
  [GroupByType.PickupType]: (barge: NominatedBarge) => pickupTypeLabels[barge.pickupType] || NOT_AVAILABLE,
  [GroupByType.HullType]: (barge: NominatedBarge) => barge.hullType || NOT_AVAILABLE,
  [GroupByType.TripStatus]: (barge: NominatedBarge) =>
    barge.tripStatus ? formatTripStatus(barge.tripStatus) : NOT_AVAILABLE,
  [GroupByType.CurrentLocationAndFleet]: (barge: NominatedBarge) => {
    const currentLocation = barge.currentLocation ? showRiverLocation(barge.currentLocation) : null
    const fleetName = barge.fleet?.name || null

    return formatLocationAndFleet(currentLocation, fleetName)
  },
}

const grouped = (
  barges: NominatedBarge[],
  groupByAttribute: GroupByType
): Array<{ kind: string; key: string } | NominatedBarge> => {
  const keyExtractor: ExtractorFunction = groupByFunctions[groupByAttribute]

  return R.pipe(
    R.groupBy(keyExtractor),
    R.toPairs,
    R.sortBy(pair => R.head(pair)),
    R.chain(([key, groupedBarges]) => (groupedBarges ? [{ kind: 'group', key }, ...groupedBarges] : []))
  )(barges)
}

function NominatedBargesTableHeader({
  keys,
  sorting,
  handleSorting,
  columnStyles,
}: {
  keys: ColumnKey[]
  sorting?: SortingState<NominatedBarge | NominatedBargeWithReview>
  handleSorting: (column: Sortable<NominatedBarge | NominatedBargeWithReview>) => void
  columnStyles: Theme['columns']
}) {
  return (
    <>
      {keys.map(key => (
        <HeaderCell className={columnStyles[key]} key={key}>
          <SortableColumn
            isSorted={columns[key] === sorting?.sortable ? sorting?.dir : undefined}
            handleToggleSorting={() => handleSorting(columns[key])}>
            {columns[key].label}
          </SortableColumn>
        </HeaderCell>
      ))}
    </>
  )
}

type NominatedBargesTableRowProps = { row: NominatedBarge | NominatedBargeWithReview; keys: ColumnKey[] }

function NominatedBargesTableRow({ row, keys }: NominatedBargesTableRowProps) {
  const { tripStatuses } = useSettingsContext()
  const { tripStatus, isAtRisk, isAvailable, cargo, tboInfo, inOtherNomination } = columns

  return (
    <>
      {keys.map(key => (
        <TableCell key={key}>
          {match(key)
            .with('tripStatus', () => {
              const value = tripStatus.getValue(row)

              return isTripStatus(value) ? showTripStatus(value, tripStatuses) : null
            })
            .with('isAtRisk', () => <RiskLevelEntry risk={isAtRisk.getValue(row)} />)
            .with('inOtherNomination', () => {
              const otherNominations = inOtherNomination.getValue(row)
              return (
                <Tooltip
                  componentsProps={{ popper: { sx: { whiteSpace: 'pre' } } }}
                  title={otherNominations ?? 'Not nominated in other nominations'}
                  placement="top">
                  <ContentCopy
                    className={classnames(styles.inOtherNominations, {
                      [styles.hasOtherNominations]: typeof otherNominations === 'string',
                    })}
                  />
                </Tooltip>
              )
            })
            .with('isAvailable', () => {
              const hasLeftThePool = isAvailable.getValue(row)

              return (
                <Tooltip title="available" placement="top">
                  <>
                    {hasLeftThePool ? (
                      <Block className={styles.notAvailable} />
                    ) : (
                      <Check className={styles.available} />
                    )}
                  </>
                </Tooltip>
              )
            })
            .with('tboInfo', () => {
              const value = tboInfo.getValue(row)
              const hasReceivedTBO = isNominatedBargeWithReview(row) && row.review.receivedTBO

              return (
                <Tooltip title={value ?? 'No TBO info'} placement="top">
                  <Grading
                    className={classnames(styles.tboIcon, {
                      [styles.hasTBO]: typeof value === 'string',
                      [styles.hasReceivedTBO]: hasReceivedTBO,
                    })}
                  />
                </Tooltip>
              )
            })
            .with('cargo', () => <Ticker>{cargo.getValue(row)}</Ticker>)
            .otherwise(() => columns[key].getValue(row))}
        </TableCell>
      ))}
    </>
  )
}

export function NominatedBargesTable({
  hasReviews = false,
  barges,
  className,
  theme,
  groupBy,
}: {
  hasReviews?: boolean
  barges: Array<NominatedBarge | NominatedBargeWithReview>
  className?: string
  theme: Theme
  groupBy?: GroupByType
}) {
  const tableColumnKeys = hasReviews ? columnWithReviewKeys : columnKeys

  const { preSelectedBarges, preExcludedBarges, togglePreExclusionFor, togglePreSelectionFor } =
    useBargeSelectionBridge()

  const { sorting, setSorting } = useSorting<NominatedBarge | NominatedBargeWithReview>()

  const rows = useMemo(() => {
    if (sorting !== undefined) {
      const sortedRows = sorting.sortable.sortBy(barges)

      return sorting.dir === 'desc' ? sortedRows.reverse() : sortedRows
    }
    const effectiveGroupBy = groupBy ?? GroupByType.DropOffFacility
    return grouped(barges, effectiveGroupBy)
  }, [barges, sorting, groupBy])

  const togglePin = useCallback((id: string) => togglePreSelectionFor(id), [togglePreSelectionFor])

  const toggleExclude = useCallback((id: string) => togglePreExclusionFor(id), [togglePreExclusionFor])

  return (
    <div className={classnames(styles.tableContainer, className)}>
      <TB className={classnames(styles.table, styles.nominationTable)}>
        <TBHead>
          <TBR>
            <HeaderCell className={styles.actionCell}>Pin</HeaderCell>
            <HeaderCell className={styles.actionCell}>Exclude</HeaderCell>
            <NominatedBargesTableHeader
              columnStyles={theme.columns}
              sorting={sorting}
              handleSorting={setSorting}
              keys={tableColumnKeys}
            />
          </TBR>
        </TBHead>
        <TBBody>
          {rows.map((row, index) =>
            'kind' in row ? (
              <TBR key={`${row.key}-${index}`} isOdd={sorting !== undefined && index % 2 > 0}>
                <TableCell
                  colSpan={tableColumnKeys.length + 2}
                  key={row.key}
                  className={classnames(styles.groupHeader, styles.nominatedGroupHeader)}>
                  {row.key}
                </TableCell>
              </TBR>
            ) : (
              <TBR
                key={`${row.id}-${index}`}
                isOdd={sorting !== undefined && index % 2 > 0}
                className={classnames({
                  [styles.isOdd]: index % 2 > 0,
                  [styles.isEmpty]: row.expectedLoadStatus === LoadStatus.Empty,
                })}>
                <TableCell className={styles.actionCell}>
                  <button onClick={() => togglePin(row.id)}>
                    <PushPin
                      className={classnames(styles.pin, {
                        [styles.isPinned]: preSelectedBarges.indexOf(row.id) >= 0 || row.isPreselected,
                      })}
                    />
                  </button>
                </TableCell>
                <TableCell className={styles.actionCell}>
                  <button onClick={() => toggleExclude(row.id)}>
                    <Cross isExcluded={preExcludedBarges.indexOf(row.id) >= 0} />
                  </button>
                </TableCell>
                <NominatedBargesTableRow keys={tableColumnKeys} row={row} />
              </TBR>
            )
          )}
        </TBBody>
      </TB>
    </div>
  )
}

export function UpdatedNominationBargesTable({
  barges,
  remainingBargeIds,
  className,
}: {
  barges: NominatedBarge[]
  remainingBargeIds: string[]
  className?: string
}) {
  const tableColumnKeys = useMemo(() => columnKeys, [])

  const { sorting, setSorting } = useSorting<NominatedBarge>()

  const rows = useMemo(() => {
    if (sorting !== undefined) {
      const sortedRows = sorting.sortable.sortBy(barges)

      return sorting.dir === 'desc' ? sortedRows.reverse() : sortedRows
    }

    return barges.slice().sort((a, b) => {
      const isNewA = !remainingBargeIds.includes(a.id)
      const isNewB = !remainingBargeIds.includes(b.id)

      if (isNewA === isNewB) return 0
      return isNewA ? -1 : 1
    })
  }, [barges, remainingBargeIds, sorting])

  return (
    <div className={classnames(styles.tableContainer, className)}>
      <TB className={classnames(styles.table, styles.nominationTable)}>
        <TBHead>
          <TBR>
            <NominatedBargesTableHeader
              sorting={sorting}
              handleSorting={setSorting}
              keys={tableColumnKeys}
              columnStyles={defaultTheme.columns}
            />
          </TBR>
        </TBHead>
        <TBBody>
          {rows.map((row, index) => (
            <TBR
              key={`${row.id}-${index}`}
              isOdd={index % 2 > 0}
              className={classnames({
                [styles.isUpdated]: sorting === undefined && !remainingBargeIds.includes(row.id),
                [styles.isOdd]: index % 2 > 0,
              })}>
              <NominatedBargesTableRow keys={tableColumnKeys} row={row} />
            </TBR>
          ))}
        </TBBody>
      </TB>
    </div>
  )
}

export function GoalNominatedBargesTable({
  goalCount,
  barges,
  className,
}: {
  goalCount: Record<string, number>
  barges: NominatedBarge[]
  className?: string
}) {
  const [groupBy] = useState<GroupByType>(GroupByType.DropOffFacility)

  const { sorting, setSorting } = useSorting<NominatedBarge | NominatedBargeWithReview>()

  const rows = useMemo(() => {
    if (sorting !== undefined) {
      const sortedRows = sorting.sortable.sortBy(barges)

      return sorting.dir === 'desc' ? sortedRows.reverse() : sortedRows
    }

    return grouped(barges, groupBy)
  }, [barges, sorting, groupBy])

  return (
    <div className={classnames(styles.tableContainer, className)}>
      <TB className={classnames(styles.table, styles.nominationTable)}>
        <TBHead>
          <TBR>
            <NominatedBargesTableHeader
              sorting={sorting}
              handleSorting={setSorting}
              keys={columnKeys}
              columnStyles={defaultTheme.columns}
            />
          </TBR>
        </TBHead>
        <TBBody>
          {rows.map((row, index) => {
            const numberOfGoals = 'kind' in row ? 1 : goalCount[row.id] ?? 1

            return 'kind' in row ? (
              <TBR key={`${row.key}-${index}`} isOdd={sorting !== undefined && index % 2 > 0}>
                <TableCell
                  colSpan={columnKeys.length}
                  key={row.key}
                  className={classnames(styles.groupHeader, styles.nominatedGroupHeader)}>
                  {row.key}
                </TableCell>
              </TBR>
            ) : (
              <TBR
                key={`${row.id}-${index}`}
                isOdd={sorting !== undefined && index % 2 > 0}
                className={classnames({
                  [styles.twoGoals]: numberOfGoals === 2,
                  [styles.threeGoals]: numberOfGoals === 3,
                  [styles.fourGoals]: numberOfGoals === 4,
                })}>
                <NominatedBargesTableRow keys={columnKeys} row={row} />
              </TBR>
            )
          })}
        </TBBody>
      </TB>
    </div>
  )
}
