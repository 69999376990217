import React, { FC } from 'react'

import styles from './styles.module.scss'

type MaxDraftFormProps = {
    feet: number | null
    inch: number | null
    onChange: (feet: number | null, inch: number | null) => void
    readOnly: boolean
}

const MaxDraftForm: FC<MaxDraftFormProps> = ({ feet, inch, onChange, readOnly }) => {

    const handleFeetChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.valueAsNumber
        onChange(Number.isNaN(value) ? null : value, inch)
    }

    const handleInchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.valueAsNumber
        onChange(feet, Number.isNaN(value) ? null : value)
    }

    const handleFeetBlur = () => {
        if (feet === 0) {
            onChange(null, inch)
        }
    }

    const handleInchBlur = () => {
        if (inch === 0) {
            onChange(feet, null)
        }
    }

    return (
        <div className={styles.formRow}>
            <label className={styles.label}>Draft</label>
            <div className={styles.draught}>
                <div className={styles.draughtEntry}>
                    <span>Feet</span>
                    <input
                        type="number"
                        value={feet ?? ''}
                        min={0}
                        onChange={handleFeetChange}
                        onBlur={handleFeetBlur}
                        placeholder="0"
                        className={`${styles.nr} ${readOnly ? styles.readOnly : ''}`}
                        readOnly={readOnly}
                    />
                </div>
                <div className={styles.draughtEntry}>
                    <span>Inch</span>
                    <input
                        type="number"
                        value={inch ?? ''}
                        min={0}
                        max={11}
                        onChange={handleInchChange}
                        onBlur={handleInchBlur}
                        placeholder="0"
                        className={`${styles.nr} ${readOnly ? styles.readOnly : ''}`}
                        readOnly={readOnly}
                    />
                </div>
            </div>
        </div>
    )
}

export default MaxDraftForm
