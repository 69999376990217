import React, { FC } from 'react'

import classnames from 'classnames'

import { PrimaryMenu } from '../../../components/Header/PrimaryMenu/PrimaryMenu'
import { Layout } from '../../../ui/Layout/Layout'

import BargePoolBlock from './BargePoolBlock'
import DepartureTimeSelectionBlock from './DepartureTimeSelectionBlock'
import LaneSelectionBlock from './LaneSelectionBlock'
import PoolFiltersSelectionBlock from './PoolFilters/PoolFiltersSelectionBlock'
import styles from './styles.module.scss'
import TowParametersSelectionBlock from './TowParameters/TowParametersSelectionBlock'
import { NominationStage } from './types'
import VesselSelectionBlock from './Vessel/VesselSelectionBlock'
import useNominationFormViewModel from './viewModel'

type NominationFormProps = {}

const NominationFormPage: FC<NominationFormProps> = () => {
  const { stages, bargePool } = useNominationFormViewModel()

  return (
    <Layout
      primaryMenu={({ isDrawerOpen, setIsDrawerOpen }) => (
        <PrimaryMenu isDrawerOpen={isDrawerOpen} setDrawerOpen={setIsDrawerOpen} />
      )}>
      <div className={styles.container}>
        <div className={classnames([styles.section, styles.formSection])}>
          <LaneSelectionBlock {...stages[NominationStage.LaneSelection]} />
          <VesselSelectionBlock {...stages[NominationStage.VesselSelection]} />
          <DepartureTimeSelectionBlock {...stages[NominationStage.DepartureTimeSelection]} />
          <PoolFiltersSelectionBlock {...stages[NominationStage.PoolFiltersSelection]} />
          <TowParametersSelectionBlock {...stages[NominationStage.TowParametersSelection]} />
        </div>
        <div className={classnames([styles.section, styles.bargePoolSection])}>
          <BargePoolBlock {...bargePool} />
        </div>
      </div>
    </Layout>
  )
}

export default NominationFormPage
